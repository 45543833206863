import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import TextImageGrid from "../components/text-imagegrid";

import introImage from "../images/MA4A0351.jpg";

const AboutPage = () => (
  <Layout>
    <SEO title="Über uns" />
    <Intro image={introImage} headline="Über uns">
      <p>
        Mit unseren Juices und Smoothies beweisen wir, dass Gesundheit und
        Genuss genauso gut zusammenpassen, wie Apfel und Banane. Und: Dass
        frische, reife Früchte so süß und lecker sind, dass sie nicht das
        kleinste Löffelchen Zucker benötigen, um Schluck für Schluck
        Glücksgefühle freizusetzen.
      </p>
    </Intro>
    <TextImageGrid
      headline="Warum o’mellis?"
      backgroundDark={true}
      reverse={true}
      smallColumn={true}
      images={[
        require("../images/neu/erdbeeren.jpg"),
        require("../images/neu/3ersmoothies-oben.jpg")
      ]}
    >
      <p>
        Äpfel, Karotten, Birnen, rote Rüben, Erdbeeren und Spinat – all das und
        noch viel mehr wächst auf Österreichs Wiesen und Feldern. In unseren
        Smoothies und Juices steckt deshalb auch nur das frischeste Obst und
        Gemüse. Aber auch exotischere Vitaminspender dürfen bei uns ordentlich
        mitmischen: Orange, Kiwi und Banane sind bei o’mellis immer erste Sahne!
      </p>
      <p>
        Derzeit versorgen wir an 10 Standorten in Österreich monatlich mehr als
        70.000 zufriedene Kunden mit frisch gepressten Säften und Smoothies:
      </p>
      <p>
        BORA BORA klingt wie ein pazifischer Inseltraum und schmeckt auch so.
        Mango, Kiwi & Co: Unser Smoothie HONOLULU ist Hawaii in Flaschen.
        Benannt nach der Insel der Erdbeerbäume: KORSIKA ist cremig-sanfter
        Genuss.
      </p>
    </TextImageGrid>
    <TextImageGrid
      headline="Lust auf Smoothies"
      link={{ url: "/standorte/", label: "Hier findest du uns" }}
      images={[require("../images/neu/ananas.jpg")]}
      wideImage={true}
      linkBeforeChildren={true}
      smallColumn={true}
      hideImageMobile={true}
    >
      <img src={require("../images/neu/lustaufsmoothies_2.jpg")} />
    </TextImageGrid>
  </Layout>
);

export default AboutPage;
